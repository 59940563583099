footer {
	background-image: url(RESOURCE/img/bg-footer.jpg);
	padding: 100px;
	background-repeat: no-repeat;
	background-position: left top;
	background-origin: border-box;
	background-color: var(--primary);
	background-size: 28%;
	padding: 40px 0;
	z-index: 1;

	@media (max-width:992px) {
		background-size: 58%;
	}

	.doc-box {
		.lead {
			a {
				color: var(--primary);

				@media (min-width: 767px) {
					font-size: 21px;
				}

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	.widget-ft {
		padding: 30px 30px 50px;
		text-align: center;

		@media (max-width:992px) {
			padding: 0 30px 20px;
		}

		.widget-title {
			margin-bottom: 30px;
			font-size: 32px;
			color: var(--white);
		}

		p {
			font-size: 16px;
			color: var(--white);

			a {
				color: var(--white);
			}
		}
	}



	.list-inline {
		margin: 28px 0 16px;

		@media (max-width:767px) {
			text-align: center;
		}

		li {
			span {
				color: var(--white);
				font-family: GT Pressura normal, sans-serif;
			}

			a {
				font-family: GT Pressura normal, sans-serif;
			}
		}
	}

	.footer-link {
		color: var(--white);
	}

	.text-sm-right {
		text-align: right;

		@media (max-width:767px) {
			text-align: center;
		}
	}

	.footer-social-icons {
		margin: 15px 0;

		a {
			display: inline-flex !important;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			width: 42px;
			text-decoration: none;
			border-radius: 50%;
			height: 42px;
			color: var(--primary);
			background-color: var(--white);
			margin-left: 13px;

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				background-color: #e6e6e6;
			}
		}
	}
}

.main-footer {
	z-index: 1;
}