.indexpage {
	.unit-btn {
		text-align: right !important;
	}

	.searchbar {
		.form-control {
			padding: 0 15px;
			font-size: 16px;
		}

		.second {
			.search-popup-trigger {
				border-radius: 0;
				text-align: left;

				@media (max-width: 992px) {
					border-radius: 2em;
				}
			}
		}
	}



	.brief {
		padding-top: 80px;
		padding-bottom: 115px;
		font-family: 'Open Sans', Helvetica Neue, Sans-serif;
		line-height: 28px;
		font-size: 16px;


		.content-section {
			margin-top: 15px;
		}

		h2 {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		p {
			margin-top: 40px;
			color: #555;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;
		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			margin-top: 25px;
			overflow: hidden;
		}

		.brief-image-left img {
			float: left;
			width: 95%;
		}


	}

	.feat-icon-bl {
		padding: 30px 0;

		@media (max-width:992px) {
			padding: 12px 0;
		}

		img {
			display: inline-block;
			margin-bottom: 30px;
			max-height: 80px;
		}

		h4 {
			font-size: 17px;
			margin-bottom: 20px;
			color: var(---li-black2);
		}

		p {
			font-size: 17px;
		}
	}

	.owl-dots {
		width: 100%;
		text-align: center;
		margin-bottom: 25px;
		position: relative;
		bottom: 15px;

		.owl-dot {
			width: 10px;
			height: 10px;
			background-color: var(--bg-grey);
			margin: 0 2px;
			border-radius: 50%;

			&.active {
				background-color: var(--primary);
			}
		}
	}

	.owl-nav {

		button {
			position: absolute;
			top: 220px;
			overflow: hidden;


			span {
				color: var(--white);
				font-size: 54px;
				width: 40px;
				height: 40px;
				display: inline-block;
				border-radius: 50%;
				line-height: 26px;
				text-indent: -9999px;
				background-image: url(RESOURCE/img/icon-angle.png);
				background-repeat: no-repeat;
				background-position: center;
				background-color: var(--primary);
			}

			&.owl-prev {
				left: -20px;
				transform: rotate(90deg);
			}

			&.owl-next {
				right: -20px;
				transform: rotate(-90deg);

			}

		}

	}

	.slide-single-unit {
		position: relative;

		&:before {
			z-index: 1;
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .3);

		}

		.unit-item {
			position: relative;
			display: block;

			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center;
				object-position: center;
				height: 320px;

				@media (min-width:993px) {
					height: 90vh;
				}
			}

			.unit-dt {
				z-index: 2;
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				text-align: center;

				h2 {
					color: var(--white);
					font-size: 50px;
					margin-bottom: 30px;
				}
			}
		}
	}


	.selection-plate {
		position: relative;
		overflow: hidden;
		display: block;
		margin-bottom: 20px;
		height: 320px;



		@media (min-width:993px) {
			height: 450px;
		}


		a {
			background-color: var(--dblack);
			display: block;


			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center center;
				object-position: center center;
				height: 320px;

				@media (min-width:993px) {
					height: 450px;
				}

				@media (max-width:767px) {
					height: 380px;
				}

			}

			figcaption {
				font-size: 52px;
				position: absolute;
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				color: var(--white);
				text-align: center;
			}

			&:hover {
				img {
					opacity: .7;
				}
			}
		}
	}






	.grey-bg {
		border-top: 1px solid #eaebec;
		border-bottom: 1px solid #eaebec;
		background: #F7F8FA;
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	.unit-list-section {
		.col-md-4 {
			&:nth-child(5n-1) {
				display: none;
			}
		}
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

}