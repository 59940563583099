.blog {

	.blog-entry {
		color: #333;
		display: block;
		text-decoration: none;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px dotted #7c949f;

		.blog-header {
			margin-bottom: 10px;
		}

		h2 {
			font-size: 24px;
			margin-top: 0;
		}

		&:last-child {
			border-bottom: none;
		}

	}

}

.blog-content-bl {
	.inner-banner {
		min-height: 500px;
		-webkit-background-size: cover;
		background-size: cover;
		position: relative;
		background-position: center;
		background-repeat: no-repeat;

		&.bg-blog {
			background-image: url(RESOURCE/img/bg-search-banner.jpg);
			background-position: center top;
		}

		.container {
			min-height: 500px;
			text-align: center;
			position: relative;
			z-index: 1;
			color: var(--white);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
		}

		.home-btm {
			position: absolute;
			bottom: 30px;
			text-align: center;

			a {
				display: block;
				color: var(--white);


				.icon-angle {
					border: 1.5px solid #fff;
					width: 40px;
					height: 40px;
					background-image: url(https://r.v-office.com/preview/v28/1588484719000/img/icon-angle.png);
					background-repeat: no-repeat;
					background-position: 50%;
					display: inline-block;
					border-radius: 50%;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}

	}
}

.blogentry {
	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: #ddd;
				padding: 5px;
				display: block;

			}
		}
	}
}