:root {
	--introImg: url(RESOURCE/img/bg-home-banner.jpg);
	--introMobileImg: url(RESOURCE/img/bg-home-banner.jpg);
	--navbarbreak: 992px;
	--grey: #8a8a8a;
	--primary: #aa7e37;
	--white: #fff;
	--black: #0a0a0a;
	--li-black: #5c5c5c;
	--li-black2: #575756;
	--dblack: #000;
	--dprimary: #9f660a;
	--bg-grey: #eceff2;
	--white2: #fefefe;
	--cal_not_available: #cd7898;
}