.booking-view {
	padding-top: 50px;



	.pd-form {
		label {
			font-weight: normal;
			text-align: left;
			font-size: 18px;
		}


	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			.checkbox {
				margin-bottom: 15px;
			}

			label {
				font-weight: 700;

			}

			a {
				color: var(--primary);
				font-size: 16px;
				cursor: pointer;
				position: relative;
				top: 3px;

				&:hover {
					color: var(--dprimary);
				}
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


}

.inner-banner {
	height: 300px;
	overflow: hidden;
	position: relative;

	.cover-img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		background-position: center;
		position: absolute;
	}

	.container {
		min-height: 300px;
		text-align: center;
		position: relative;
		z-index: 1;
		color: var(--white);
		display: flex;
		align-items: flex-end;
	}

	&:before {
		content: ' ';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		left: 0;
		background-color: rgba(0, 0, 0, .5);
	}

	.hero {
		text-align: center;
		color: var(--white2);
		background: none;
		padding: 0 0 50px;
	}
}

.cart-details {
	padding: 15px;
	background-color: var(--bg-grey);
	overflow: hidden;
	max-width: 460px;

	.unit-details {
		margin-top: 10px;
		padding-left: 20px;

		li {
			margin-left: 0px;
		}
	}
}