@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}


/*.list-view {
	.map-col {
		.affix {
			@media (min-width:993px) {
				position: fixed !important;
				top: 40px;
				width: 100%;
				max-width: 390px;
			}
		}
	}
}*/
.list-view {
	.map-pane {
		display: none;
	}
}

.three-units {
	.col-sm-4 {
		&:nth-child(3n+4) {
			@media (min-width:768px) {
				clear: both;
			}
		}
	}
}

.search-contn-bl {
	position: relative;
	z-index: 2;
	margin-bottom: 20px;
}

.map-view {
	margin-bottom: 30px;

	.map-col {

		.affix,
		.affix-bottom {
			@media (min-width:993px) {
				position: relative !important;
				top: 0 !important;
				width: 100%;
				padding-right: 0;
				max-width: inherit;
			}
		}
	}

	.map-unit-preview {
		font-family: GTPressura;

		h6 {
			font-size: 17px;
			list-style: none;
			padding-bottom: 5px;
			color: var(--li-black);
			font-family: GTPressura;
			margin: 10px 0 0 0;
		}

		h3 {
			font-size: 24px;
			font-family: GTPressura;
			color: #333;
			margin: 0 0 10px 0;

		}

		.search-result-properties {
			li {
				font-size: 17px;
				list-style: none;
				padding-bottom: 5px;
				color: var(--li-black);
				line-height: 1.42857143;
			}
		}

		.ratings {
			letter-spacing: 4px;
			font-size: 16px;
			margin: 10px 0 10px 0;

			.fa {
				color: var(--primary);
			}
		}

		.unit-btn {
			a {
				color: #fff !important;
			}
		}
	}
}


.map-col {
	.affix {
		@media (max-width:992px) {
			position: relative !important;
		}

		@media (min-width:993px) {
			width: 33.33%;
			max-width: inherit;
			padding-right: 30px;
			top: 60px;
		}

		@media (min-width:1440px) {
			position: fixed !important;
			top: 50px;
			width: 100%;
			max-width: 450px;
		}

	}

	.affix-bottom {
		@media (max-width:992px) {
			position: relative;
			top: inherit !important;
		}
	}
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.search-banner {
		min-height: 500px;
		height: 66vh;
		background: url(RESOURCE/img/bg-search-banner.jpg);
		-webkit-background-size: cover;
		background-size: cover;
		position: relative;

		.container {
			min-height: 500px;
			text-align: center;
			position: relative;
			z-index: 1;
			color: var(--white);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
		}

		.hero {
			text-align: center;
			color: var(--white2);
			background: none;
			padding: 0;
		}

		.home-btm {
			position: absolute;
			bottom: 30px;
			text-align: center;

			a {
				display: block;
				color: var(--white);


				.fa {
					border: 2px solid #fff;
					width: 40px;
					height: 40px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					font-size: 22px;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.result-pane {

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			h3 {
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media (max-width: 767px) {
					flex-wrap: wrap;
				}

				>span {
					@media (max-width: 767px) {
						padding-right: 15px;
					}
				}

				small {
					padding: 10px 0 10px 0px;
				}

				.fa {
					font-size: 13px;
					margin: 0 0 0 5px;
				}
			}

			.sorting-trigger {
				cursor: pointer;

				i {
					border: 1px solid;
					border-radius: 50%;
					padding: 10px;
					background: #aa7e37;
					color: white;
				}
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.main-price {
			text-decoration: line-through;
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.gm-style-iw-d {
			padding-left: 5px;
		}

		.result-pane {
			display: none;
		}

		.col-md-4 {
			width: 100%;
		}
	}

	.map-section {
		height: 80vh;
		min-height: 550px;
	}

	.map-pane {
		z-index: -1;

		.map-main {
			height: 700px;
			padding: 0 0 70px 0;

			@media (max-width: 767px) {
				height: 530px;
				padding: 0 0 98px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				padding: 0 0 96px;
			}


			&.no-map {
				padding: 0 0 0 0;
			}

			.mapnote {
				font-size: 18px;
				margin: 0 0 20px;

				@media (max-width: 767px) {
					font-size: 14px;
					min-height: 80px;
				}

				a {
					color: var(--primary);

					&:hover {
						color: var(--dprimary);
					}
				}
			}

			.mapconsent {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				background: rgba(26, 121, 187, .15);
				flex-direction: column;
				padding: 0 15px;

				>p {
					margin: 0 0 20px;
					font-size: 18px;
					font-weight: 600;

					@media (max-width: 767px) {
						font-size: 16px;
					}

					a {
						color: var(--primary);

						&:hover {
							color: var(--dprimary);
						}
					}
				}

				>a,
				.footer-link {
					background: var(--primary);
					color: #fff;
					border-radius: 100px;
					padding: 12px 20px;
					display: block;
					font-size: 18px;

					&:hover {
						background: var(--dprimary);
					}

					@media (max-width: 767px) {
						font-size: 16px;
					}
				}

				.box {
					.body {
						text-align: left;
					}
				}
			}

			.flex {
				height: 100%;

				>div {
					height: 100%;
				}
			}
		}

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: left;

		.search-result-properties {
			li {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				i {
					margin-right: 10px;
				}
			}
		}

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;

			.unit-btn {
				.offername {
					font-size: 17px;
					font-weight: 400;
				}
			}

		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
			font-size: 18px;
		}

		.altheading {
			color: #00b900;
		}

		h4 {
			margin-top: 0;
		}

		@media (max-width:768px) {
			.no-search-results {
				font-size: 16px;
			}
		}


	}

	.image-block {
		.map-marker {
			position: absolute;
			bottom: 10px;
			left: 15px;
			font-size: 16px;
			color: #fff;
			color: var(--white);
		}
	}

	.unit-cont-home {


		.udetails {
			min-height: 120px;

			li {
				display: flex;
				align-items: center;

				i {
					margin-right: 10px;
				}
			}
		}
	}




	.adv-filt-form {
		margin-top: 20px;
		display: block !important;

		.select-group>label {
			text-align: center;
			color: var(--grey);
			font-size: 14px;
			display: block;
			text-align: left;

		}
	}

	.search-cont-bl {
		position: relative;
		z-index: 2;
		margin-bottom: 15px;
	}


	.search-bar {


		>.container {
			position: relative;
			padding: 25px 15px 40px;
		}

		.map-link {


			a {
				font-size: 16px;
				color: var(--primary);
			}
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}




		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;
			z-index: 10;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			-webkit-box-flex: 1;
			flex: 1 1 0;
			min-width: 0;
			min-height: 0;
			height: auto;

		}

		.result-pane {


			.more-btn-panel {
				display: none;
			}


		}


		.results {}



	}





}



.alternativesPrice {
	min-height: 70px;
	color: var(--li-black);
	font-size: 17px;

	@media (min-width:768px) and (max-width:992px) {
		min-height: 130px;
	}

	h5 {
		font-size: 17px;
	}

	.fa-calendar {
		margin-right: 10px;
	}

	.unit-btn {
		background-color: var(--primary);
		color: #fff;
		padding: 5px 15px;
		display: inline-block;
		border-radius: 50px;
		margin-bottom: 5px;

		.invalid {
			opacity: 0.7;
		}

		.special {
			color: #fff;
		}

	}

	.offername {
		width: 100%;
		display: inline-block;
		padding-top: 5px;
	}

}

.search-bar {

	.form-control {
		background: #eee;
		height: auto;
		min-height: 55px;
		padding: 0 15px;
		font-size: 16px;

		&.night-select {
			position: relative;
			margin: 10px 0 -30px 0;

			@media (max-width: 991px) {
				position: static;
			}
		}
	}


	.search-btn {
		@media (max-width: 992px) {
			margin-bottom: 20px !important;
		}
	}
}