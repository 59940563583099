@import "common.css";
@import "setup.css";

.inner-pages {
	.inner-banner {
		min-height: 500px;
		height: 66vh;
		-webkit-background-size: cover;
		background-size: cover;
		position: relative;
		background-position: center;
		background-repeat: no-repeat;

		&.agb {
			background-image: url(RESOURCE/img/bg-banner-agb-new.jpg);
		}

		&.angebote {
			background-image: url(RESOURCE/img/pauschalen_hero.jpg);
		}

		&.archsum {
			background-image: url(RESOURCE/img/ferienhaeuser-in-archsum.jpg);
		}

		&.flat-morsum {
			background-image: url(RESOURCE/img/ferienwohnungen-in-morsum.jpg);
		}

		&.house-moursum {
			background-image: url(RESOURCE/img/ferienhaeuser-in-morsum.jpg);
		}

		&.flat-archsum {
			background-image: url(RESOURCE/img/ferienwohnungen-in-archsum.jpg);
		}

		&.house-keitum {
			background-image: url(RESOURCE/img/ferienhaueser-in-keitum.jpg);
			background-position: center top;
		}

		&.with-family {
			background-image: url(RESOURCE/img/familienurlaub-sylt.jpg);
		}

		&.team {
			background-image: url(RESOURCE/img/team-neu.jpg);
			background-position: center top;
		}

		&.service {
			background-image: url(RESOURCE/img/service_hero.jpg);
		}

		&.with-dog {
			background-image: url(RESOURCE/img/urlaub-mit-hund-sylt.jpg);
			background-position: center -45px;
		}

		&.with-sauna {
			background-image: url(RESOURCE/img/ferienhaus-sylt-mit-sauna.JPG);
		}

		&.with-fireside {
			background-image: url(RESOURCE/img/ferienhaus-sylt-mit-kamin.jpg);
		}

		&.with-seaview {
			background-image: url(RESOURCE/img/luxus-ferienhaus-sylt-mit-meerblick.jpg);
		}

		&.bioburners {
			background-image: url(RESOURCE/img/biikebrennen-sylt.jpg);
		}

		&.in-winters {
			background-image: url(RESOURCE/img/sylt-im-winter.jpg);
		}

		&.beachchair {
			background-image: url(RESOURCE/img/banner-sylt-strandkorb.jpg);
		}

		.container {
			height: 66vh;
			min-height: 500px;
			text-align: center;
			position: relative;
			z-index: 1;
			color: var(--white);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
		}

		.hero {
			text-align: center;
			color: var(--white2);
			background: none;
			padding: 0;
		}

		.home-btm {
			position: absolute;
			bottom: 30px;
			text-align: center;

			a {
				display: block;
				color: var(--white);


				.icon-angle {
					border: 1.5px solid #fff;
					width: 40px;
					height: 40px;
					background-image: url(https://r.v-office.com/preview/v28/1588484719000/img/icon-angle.png);
					background-repeat: no-repeat;
					background-position: 50%;
					display: inline-block;
					border-radius: 50%;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.vo-notfound-body {
	.vo-nav-bar {
		background-color: var(--bg-grey);
		padding: .5rem 2.5rem .5rem;

		.logo2 {
			display: inline-block;
		}

		.logo {
			display: none;
		}

		.main-menu li>a {
			color: var(--li-black2) !important;
		}

		.dropbtn,
		.drop-nav>a {
			color: var(--li-black2) !important;
		}

		.icon-contact {
			background-image: url(RESOURCE/img/icon-chat-black.png) !important;
		}
	}
}

.not-found {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 500px;
	color: var(--primary);
}

.contact-form {
	.control-label {
		margin-bottom: 10px;
	}

	.hide-label {
		display: none;
	}

	.radio-label {
		color: white;
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: white;
		opacity: 1;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: red;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: red;
	}

	.form-control {
		background-color: #a1adb5;
		border: 1px solid #a1adb5;
		color: var(--black);

		&:focus {
			border: 1px solid #8a8a8a;
		}
	}
}

.unit-forms {
	.form-control {
		background: #eee;
		height: auto;
		min-height: 55px;
		padding: 0 15px;
		font-size: 16px;
	}

	.search-btn {
		@media (max-width: 992px) {
			margin-bottom: 20px !important;
		}
	}
}

.team-slider {
	.team-box {
		padding: 0 15px;
	}

	.owl-nav {
		@media (max-width: 767px) {
			display: none;
		}
	}

	.owl-dots {
		@media (max-width: 767px) {
			display: flex !important;
			align-items: center;
			justify-content: center;
			margin: 10px 0 0 0;
		}

		.owl-dot {
			width: 10px;
			height: 10px;
			background-color: #eceff2;
			background-color: var(--bg-grey);
			margin: 0 2px;
			border-radius: 50%;

			&.active {
				background-color: var(--primary);
			}

		}
	}

	.owl-nav {


		button {
			position: absolute;
			top: 130px;
			overflow: hidden;


			span {
				color: var(--white);
				font-size: 54px;
				width: 40px;
				height: 40px;
				display: inline-block;
				border-radius: 50%;
				line-height: 26px;
				text-indent: -9999px;
				background-image: url(RESOURCE/img/icon-angle.png);
				background-repeat: no-repeat;
				background-position: center;
				background-color: var(--primary);
			}

			&.owl-prev {
				left: -20px;
				transform: rotate(90deg);
			}

			&.owl-next {
				right: -20px;
				transform: rotate(-90deg);

			}
		}
	}

	.img {
		text-align: center;

		img {
			width: 270px;
			height: 270px;
			object-fit: cover;
			border-radius: 100%;
			border: 15px solid var(--primary);
			margin: 0 auto;
		}
	}

	h5 {
		margin: 30px 0 0 0;
		font-size: 25px;
		text-align: center;
		line-height: 34px;
		font-weight: 500;
		text-transform: uppercase;


		span {
			display: block;
			width: 100%;
			font-weight: 300;
		}
	}
}

.wearelookingforyou {
	p {
		margin: 0 0 30px 0;
	}

	h3 {
		color: var(--li-black);
		font-size: 21px;
		font-family: GT Pressura normal, sans-serif;
		line-height: normal;
	}
}

.team-img {
	img {
		width: 100%;
	}
}

.wir-suchen {
	padding: 60px 0;

	p {
		margin: 0 0 30px 0;
	}

	.boxes {
		padding: 20px 0 20px 0;

		.img {
			text-align: center;

			img {
				width: 270px;
				height: 270px;
				object-fit: cover;
				border-radius: 100%;
				border: 15px solid var(--primary);
			}
		}

		h5 {
			margin: 30px 0 30px 0;
			font-size: 25px;
			text-align: center;
			line-height: 34px;
			font-weight: 500;

			span {
				display: block;
				width: 100%;
				font-weight: 400;
			}
		}
	}
}