@import "setup.css";

.indexpage {

	/* .searchbox {
		&.second {
			.box.full-xxs {
				.body {
					width: auto;
				}
			}
		}

	} */

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {

		min-height: 500px;
		height: 100vh;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;


		position: relative;

		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
		}


		.intro {
			text-align: center;
			height: 100vh;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 2;
			display: flex;
			min-height: 500px;

			.home-btm {
				position: absolute;
				bottom: 30px;
				text-align: center;

				a {
					display: block;
					color: var(--white);

					span {
						margin-bottom: 20px;
						display: block;
						text-transform: uppercase;
						font-size: 20px;
					}

					.icon-angle {
						border: 1.5px solid #fff;
						width: 40px;
						height: 40px;
						background-image: url(https://r.v-office.com/preview/v28/1588484719000/img/icon-angle.png);
						background-repeat: no-repeat;
						background-position: 50%;
						display: inline-block;
						border-radius: 50%;
					}

					&:hover {
						text-decoration: none;
					}
				}
			}

			.hero {
				text-align: center;
				color: #fff;
				background: none;
				padding: 0;

				.home-center {
					max-width: 295px;

					@media (max-width:992px) {
						max-width: 235px;
					}
				}

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}


	.sign-img {
		max-width: 450px;
		width: 100%;
		display: inline-block;
	}

	.searchbar {}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}

}