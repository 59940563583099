@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.image-block {
	.ribbon {
		color: var(--white);
		background-color: var(--primary);
		height: 25px;
		width: 105px;
		position: absolute;
		bottom: 16px;
		z-index: 4;
		transform: rotate(-45deg);
		right: -24px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
	}
}


.three-units {
	.srow {
		&:nth-child(3n+1) {
			@media (min-width:1200px) {
				clear: both;
			}
		}


		&:nth-child(2n+1) {
			@media (max-width:1199px) {
				clear: both;
			}
		}
	}
}


.layout-row {
	@mixin flexlayout row;
}

.ml-20 {
	margin-left: 20px;
}

.mb-10 {
	margin-bottom: 10px !important;
}

i.fa {
	&.fa-bed {
		background-image: url(RESOURCE/img/icon-user-black.png);
		width: 15px;
		height: 16px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 13px;

		&:before {
			display: none;
		}
	}
}

@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}

.searchbox.last {
	.box {
		&.full-xxs {
			.body {
				width: 300px;
			}
		}
	}
}

.guestbox {
	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	}
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}

.d-inline {
	display: inline-block;
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
		font-size: 18px;
		padding-top: 15px;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.unit-btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.special {
	color: red;

	&.offername {
		font-size: 17px;
		font-weight: 400;
		text-align: left;
		max-width: 140px;
	}
}

.asd__day--tada {
	background-color: red;
}

/*
.datepicker-trigger {
	--cal_not_available: var(--li-black2);

	.asd__day--not-available {
		background: rgba(221, 0, 17, .2) !important;
		color: #000 !important;
		opacity: 0.7;
	}

	.asd__day--disabled {
		background: rgba(221, 0, 17, .2) !important;
		color: #000 !important;
		opacity: 0.7;
	}

	.asd__day--enabled {
		
		color: #000;
	}

	.asd__day--enabled:hover {
		background: rgb(103, 246, 238);
		color: #000;
	}


	.asd__day--arrival {
		background: rgb(170, 126, 55);
		background: linear-gradient(144deg, rgba(170, 126, 55, 1) 50%, rgba(87, 87, 86, 1) 50%);
		color: #000;
	}

	.asd__day--departure {
	
		background: linear-gradient(144deg, rgba(221, 0, 17, .2) 50%, rgba(255, 255, 255, .2) 50%) !important;
		color: #000;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: rgb(102, 226, 218) !important;
		color: #000 !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: rgb(102, 226, 218) !important;
		color: #000 !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		color: #fff !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: #000 !important;
	}

	.asd__day--selected {
		background: rgb(0, 166, 153) !important;
		color: #000 !important;
		opacity: 1 !important;
	}

	.asd__day--disabled {
		&.asd__day--arrival {
			background: #575756;
		
			background: linear-gradient(144deg, rgba(221, 0, 17, .2) 50%, rgba(255, 255, 255, .2) 0) !important;
		}

		&.asd__day--in-range {
			color: rgb(255, 255, 255) !important;
			background: rgb(102, 226, 218) !important;
			border: 1px double rgb(51, 218, 205) !important;
			opacity: 1;
		}
	}



}*/

.datepicker-trigger {
	.asd__day-button {
		pointer-events: auto;
	}


	.asd__day-button:disabled,
	.asd__day-button[disabled] {
		pointer-events: none;
	}

	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected {
		background: rgb(0, 166, 153) !important;
		color: white !important;
		opacity: 1 !important;
	}


}




/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

@font-face {
	font-family: 'GTPressura';
	src: url('RESOURCE/fonts/GTPressura.eot');
	src: url('RESOURCE/fonts/GTPressura.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/GTPressura.woff2') format('woff2'),
		url('RESOURCE/fonts/GTPressura.woff') format('woff'),
		url('RESOURCE/fonts/GTPressura.ttf') format('truetype'),
		url('RESOURCE/fonts/GTPressura.svg#GTPressura') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GT Pressura normal';
	src: url('RESOURCE/fonts/GTPressura-Light.eot');
	src: url('RESOURCE/fonts/GTPressura-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/GTPressura-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/GTPressura-Light.woff') format('woff'),
		url('RESOURCE/fonts/GTPressura-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/GTPressura-Light.svg#GTPressura-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}


body {
	font-family: 'GTPressura';
	font-weight: normal;
	font-style: normal;
}

.h3,
h3 {
	font-size: 24px;
}

a:hover {
	text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5 {
	margin-top: 0;
}

.pt-0 {
	padding-top: 0 !important;
}

.unit-block {
	margin-bottom: 30px;
	text-align: left;

	.offer-bubble {
		position: absolute;
		z-index: 2;
		top: -10px;
		left: 25px;
		height: 40px;
		border-radius: 5px;
		background-color: #f50707;
		color: #fff;
		padding: 0 15px;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 992px) {
			left: 20px;
			/*	top: -538px; */
		}


		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 0;
			height: 0;
			border: 5px solid transparent;
			border-top-color: #f50707;
			border-bottom: 0;
			margin-left: -5px;
			margin-bottom: -5px;
		}
	}

	.offer-page-bubble {
		position: absolute;
		z-index: 2;
		top: -10px;
		left: 25px;
		height: 40px;
		border-radius: 5px;
		background-color: #f50707;
		color: #fff;
		padding: 0 15px;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 992px) {
			left: 20px;
			top: -10px;
		}

		@media (max-width: 767px) {
			left: 25px;
			top: -15px;
		}


		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 0;
			height: 0;
			border: 5px solid transparent;
			border-top-color: #f50707;
			border-bottom: 0;
			margin-left: -5px;
			margin-bottom: -5px;
		}
	}

	.image-block {
		position: relative;
		height: 250px;
		overflow: hidden;

		@media (max-width:992px) {
			height: 200px;
		}

		.full-img {
			object-fit: cover;
			object-position: center center;
			height: 100%;
			width: 100%;
		}

		.fav-element {
			position: absolute;
			top: 10px;
			right: 20px;
			font-size: 16px;
			color: var(--white);
		}
	}

	.unit-cont-home {
		.unit-btn {
			.btn {
				color: var(--white);
			}
		}

		.unit-title {
			color: #333;
			margin-top: 0;
			margin-bottom: 10px;
		}

		.ratings {
			letter-spacing: 4px;
			font-size: 16px;
			margin-bottom: 10px;

			.fa {
				color: var(--primary);
			}
		}

		.udetails {
			color: var(--li-black);
			min-height: 150px;

			li {
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				font-size: 17px;
				list-style: none;
				padding-bottom: 5px;

				i {
					margin-right: 10px;
				}

				span {
					margin-right: 10px;
				}
			}
		}
	}
}

.checkbox label.pt-0:after {
	top: 5px;
	left: 3px;
}

.checkbox label:after {
	top: 6px !important;
	left: 2px !important;
}

.form-horizontal .terms-bl.checkbox label:before {
	background-color: salmon !important;
}

.form-horizontal .terms-bl.checkbox label {
	color: var(--white);
}

ul {
	padding: 0;
	margin: 0 0 15px;
}

.link-li {
	list-style: none;

	li {
		padding: 2px 0;
		font-size: 16px;

		a {
			color: var(--grey);
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-15 {
	margin-bottom: 15px;
}

@media screen and (min-width: 90em) {
	html {
		font-size: 125%;
	}
}

.d-block {
	display: block;
}

a:focus,
.btn:focus {
	outline: 0;
	text-decoration: none !important;
}

.bounce {
	animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
	-moz-animation: bounce 2s infinite;
	-o-animation: bounce 2s infinite;
}

.price-table .price {
	color: var(--primary);
}

@-webkit-keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
	}

	40% {
		-webkit-transform: translateY(-15px);
	}

	60% {
		-webkit-transform: translateY(-7px);
	}
}

@-moz-keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		-moz-transform: translateY(0);
	}

	40% {
		-moz-transform: translateY(-15px);
	}

	60% {
		-moz-transform: translateY(-7px);
	}
}

@-o-keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		-o-transform: translateY(0);
	}

	40% {
		-o-transform: translateY(-15px);
	}

	60% {
		-o-transform: translateY(-7px);
	}
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-15px);
	}

	60% {
		transform: translateY(-7px);
	}
}

.btn {
	border-radius: 30px;
	font-size: 18px;
	padding: 10px 26px;

	&.btn-primary,
	&.btn-go {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--white);

		&:hover {
			background-color: var(--dprimary);
		}
	}

	&.btn-secondary {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--dprimary);
		}
	}

	&.btn-lg {
		padding: 14px 20px;
	}
}

.text-md-right {
	@media screen (min-width:993px) {
		text-align: right;
	}
}

.pad60 {
	padding: 60px 0;
}

p,
dd {
	color: var(--li-black);
	font-size: 18px;
	font-family: GT Pressura normal, sans-serif;
	font-weight: 300;

	a {
		font-weight: 500;
		color: var(--primary);
	}
}

.mt-20 {
	margin-top: 20px;
}

.h1-lower {
	font-size: 50px;

	@media (max-width:992px) {
		font-size: 38px;
	}
}

.h2-lower {
	font-size: 38px;
	margin-top: 20px;

	@media (max-width:992px) {
		font-size: 30px;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

.object-image {
	height: 450px;
	width: 100%;
	margin-bottom: 30px;

	@media (max-width:992px) {
		height: 250px;
	}

	&.cover {
		-o-object-position: center center;
		object-position: center center;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.bg-grey {
	background-color: var(--bg-grey);
	z-index: 1;
}

.bg-white {
	background-color: var(--white);
	z-index: 1;
}

.f14 {
	font-size: 14px;
	color: var(--li-black);
	font-family: GT Pressura normal, sans-serif;
	font-weight: 300;
}

ol {
	text-align: left;

	li {
		color: var(--li-black);
		font-size: 16px;
		font-family: GT Pressura normal, sans-serif;
	}
}

.subline {
	padding: 0 0 1.25rem 0;
	text-transform: uppercase;
	color: var(--black);
	font-size: 22px;
}

.ptop-25 {
	padding-top: 25px;
}

.txt-prim {
	color: var(--primary);
}

.txt-prim2 {
	color: black;
}

.mt-0 {
	margin-top: 0 !important;
}


ul.li-box {
	margin-left: 1.25rem;
	list-style-type: square;
	display: inline-block;

	li {
		text-align: left;
		font-size: 18px;
	}

	&.style2 {
		color: var(--li-black);
		font-size: 16px;
		font-family: GT Pressura normal, sans-serif;
	}
}

.li-box2 {
	margin: 0;
	padding: 0;

	li {
		text-align: left;
		font-size: 17px;
		padding: 5px 0;
		list-style: none;
		font-family: GT Pressura normal, sans-serif;
		padding-left: 15px;
		position: relative;

		&:before {
			content: "";
			width: 6px;
			height: 6px;
			background-color: #5c5c5c;
			display: inline-block;
			position: absolute;
			left: 0;
			top: 14px;
		}
	}
}

.li-box3 {
	margin: 0;
	padding: 0;

	li {
		text-align: left;
		font-size: 17px;
		padding: 5px 0;
		list-style: none;
		font-family: GT Pressura bold, sans-serif;
		padding-left: 15px;
		position: relative;

		&:before {
			content: "";
			width: 6px;
			height: 6px;
			background-color: #5c5c5c;
			display: inline-block;
			position: absolute;
			left: 0;
			top: 14px;
		}
	}
}


.min-row {
	margin: 0 -10px;

	.col-md-7,
	.col-md-5,
	.col-md-6,
	.col-md-8,
	.col-md-4,
	.col-md-9,
	.col-md-3,
	.col-sm-7,
	.col-sm-5,
	.col-sm-6 {
		padding: 0 10px;
	}
}

.page-title {
	color: #fefefe;
	text-transform: lowercase;
	font-size: 42px;

	@media (min-width: 993px) {
		font-size: 3.5rem;
	}

}

.page-titlenew {
	color: #fefefe;
	//text-transform: lowercase;
	font-size: 42px;

	@media (min-width: 993px) {
		font-size: 63px;
	}


}

.home-center {
	h4 {
		font-size: 16px;
	}
}

.bg-dark-grey {
	background-color: #b2c0c9;
}

* {
	outline: none;
}

.icon-square {
	width: 26px;
	height: 27px;
	background-image: url(RESOURCE/img/icon-square.jpg);
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center bottom;
}

.asd__wrapper--full-screen {
	z-index: 8;
}

.icon-square-grey {
	width: 17px;
	height: 17px;
	background-image: url(RESOURCE/img/icon-square2.png);
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center bottom;
	position: relative;
	top: 1px;
	background-size: 14px;
}

.breadcrumbs {
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-bottom: 0;
	display: block;
	text-align: center;
	margin: 0 auto;
	padding-left: 0;

	li {
		font-size: 18px;
		color: #0a0a0a;
		display: inline-block;

		&:after {
			content: '>';
			position: relative;
			margin: 0 .75rem;
			opacity: 1;
			color: #cacaca;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		a {
			color: var(--li-black);

			&:hover {
				color: var(--primary);
				text-decoration: none;
			}
		}

	}
}

.form-control {

	height: 55px;
	background-color: var(--bg-grey);
	font-size: 17px;
	color: var(--black);
	padding: 15px 25px;
	font-family: 'GTPressura';
	margin: 0 0 1rem;
	border-radius: 2em;
	border-color: var(--bg-grey);
	box-shadow: inherit;

	&:focus {
		background-color: var(--white);
		border-color: var(--grey);
		outline: 0;
		box-shadow: inherit;
	}
}

.form-control::-webkit-input-placeholder {
	/* Edge */
	color: var(--black);
}

.form-control:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--black);
}

.form-control::placeholder {
	color: var(--black);
}

.unit-forms {
	.search-btn {
		@media (max-width:992px) {
			margin: 0 0 20px;
		}
	}
}

.static-rating {
	text-align: center;
	letter-spacing: 2px;
	margin: 15px 0 10px;

	i {
		color: #5c5c5c;
		font-size: 16px;
	}
}

.container-fluid {
	max-width: 1440px;
	width: 100%;
}

.group-fields {
	margin: 0;

	.first {
		.form-control {
			cursor: pointer;

			@media (min-width:993px) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			border-radius: 2em;
			border-right: 3px solid #fefefe;

			&:focus {
				border-right: 1px solid var(--grey);
			}
		}
	}

	.last {
		.form-control {
			cursor: pointer;
			border-left: 3px solid #fefefe;

			@media (min-width:993px) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			border-radius: 2em;

			&:focus {
				border-left: 1px solid var(--grey);
			}
		}
	}

	.searchbox {
		padding: 0;
	}
}

.no-round {
	border-radius: 0;

	@media (max-width:992px) {
		border-radius: 2em;
	}
}

.text-prime {
	color: var(--primary);
}

.text-link,
.filter-reset,
.show-more {
	color: var(--primary);
	font-size: 16px;
	cursor: pointer;

	&:hover {
		color: var(--dprimary);
	}
}

input,
label,
.btn,
a {
	outline: 0 !important;

	&:focus {
		outline: 0 !important;
	}
}

*:focus {
	outline: none;
}

.checkbox {
	margin: 0;

	label {
		padding-left: 10px !important;
		color: var(--grey);
		font-size: 16px;


		&:before {
			width: 20px !important;
			height: 20px !important;
			border-radius: 15px !important;
			border: 2px solid #cacaca !important;
			background-color: #fff !important;
			top: 3px;
		}

		&:after {
			background-color: #cacaca;
			color: transparent !important;
			border-radius: 20px;
			left: 3px;

			top: 5px;
			border: 2px solid #fff;
			width: 15px;
			height: 15px;
		}
	}
}


.container {
	@media (max-width:767px) {
		width: 100%;
	}
}

.custom-selct {
	position: relative;

	i {
		color: var(--primary);
		position: absolute;
		right: 5px;
		top: 16px;
		font-size: 22px;
	}
}

.pb-20 {
	padding-bottom: 20px !important;
}

.divider-btns {
	position: relative;
	text-align: center;
	margin: 0 -15px;

	&.grey-style {
		margin: 0;

		&:after {
			background-color: #B3B3B3;
		}

		.btn-area {
			background-color: var(--white);

			&.bg-grey {
				background-color: var(--bg-grey);
			}
		}
	}

	&.span-text {
		font-family: GT Pressura normal, sans-serif;

		.btn {
			pointer-events: none;
		}

		&:after {
			top: 26px;
		}
	}


	&:after {
		content: "";
		position: absolute;
		top: 28px;
		background-color: #7F5F25;
		width: 100%;
		height: 1px;
		left: 0;
	}

	.btn-area {
		background-color: var(--primary);
		padding: 0 30px;
		text-align: center;
		display: inline-block;
		position: relative;
		z-index: 1;
	}

	a {
		padding: 15px;
		color: #fff;
		font-size: 16px;
		display: inline-block;
		border: 1px solid #fff;
		padding: 15px 40px;

	}


}

.three-unit-col {

	.col-md-4,
	.col-sm-4 {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}

.checkbox-success input[type=checkbox]:checked+label:after {
	content: ".";
}

.pagination {
	margin-bottom: 30px;

	li {
		margin-right: 5px;
		display: inline-block;

		a {
			border-radius: 50% !Important;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			border: none;
			font-size: 14px;
		}

		&.active {
			a {
				background-color: var(--primary);
				color: var(--white);

				&:hover {
					background-color: var(--dprimary);
				}
			}
		}
	}
}

.pad20 {
	padding: 20px 0;
}

.mt35 {
	margin-top: 35px !important;
}


.divider {
	width: 60%;
	margin: 80px auto;
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid #cacaca;
	border-left: 0;

	@media (max-width:992px) {
		margin: 40px auto;
	}
}

.no-bullet {
	li {
		display: block;
		color: var(--li-black);
		font-size: 18px;
		padding: 2px 0;
		font-family: GT Pressura normal, sans-serif;

		a {
			font-size: 17px;
		}
	}

}

.half-bdr {
	position: relative;

	&:before {
		z-index: -1;
		content: "";
		position: absolute;
		left: 15%;
		width: 70%;
		height: 2px;
		background: #e6e6e6;
		top: 145px;

		@media (min-width:993px) {
			top: 165px;
		}
	}

	.serv-home-bl {
		text-align: center;
		padding: 40px 0;


		.imgpole {
			border: 10px solid var(--primary);
			height: 200px;
			width: 200px;
			border-radius: 20em;
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;

			@media (min-width:993px) {
				height: 250px;
				width: 250px;
				border: 15px solid var(--primary);
			}
		}

		figcaption {
			margin-top: 40px;
			text-transform: uppercase;
			font-weight: 400;

			font-size: 18px;

			a {
				color: var(--dblack);
			}
		}
	}
}

#back_to_top {
	position: fixed;
	bottom: 1.25rem;
	right: 55px;
	z-index: 5;
	background-color: #e6e6e6 !important;
	border-radius: 35px;
	font-size: 1.25rem;
	padding: .5rem;
	width: 70px;
	display: inline-block;
	border-radius: 35px;
	height: 55px;
	text-align: center;

	i {

		background-image: url(RESOURCE/img/icon-arrow-top.jpg);
		background-repeat: no-repeat;
		background-position: 50%;
		width: 40px;
		height: 33px;
		display: inline-block;
	}

	@media (max-width:992px) {
		bottom: 55px;
		right: 25px;
		height: 45px;
		width: 60px;

	}
}

#back_to_top:hover {
	background-color: #DDF;
	color: #000;
}

.modal-content {
	.modal-body {
		height: 450px;
		overflow-y: auto;

		.lead {
			font-weight: 700;
			font-size: 18px;
		}

		p {
			margin: 0 0 10px;
		}
	}
}

.modal-dialog {
	overflow-y: initial !important;
	max-width: 795px;
}

.select-style {
	position: relative;

	@media (max-width:992px) {
		margin-top: 15px;
	}

	&:after {
		width: 0px;
		height: 0px;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid var(--primary);

		position: absolute;
		right: 3px;
		z-index: 2;
		top: 25px;
		content: "";
	}
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		margin: 21px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 0px 10px 10px;
			max-width: 180px;
		}

	}
}

.datepicker-trigger {
	.asd__wrapper {
		@media (max-width: 480px) {
			margin: 0 auto;
		}
	}
}

.p-md-0 {
	@media (min-width: 992px) {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.Detailsuche-btn {
	text-align: center;
	margin: 15px 0 0 0;
}

.night-select {
	border-radius: 3px;
	position: absolute;
	left: 0;
	display: none;

	@media (max-width: 1199px) {
		margin: 0 0 10px 0 !important;
		position: static;
	}

	@media (max-width: 991px) {
		border-radius: 100px;
		margin: 0 0 10px 0 !important;
		position: static;
	}
}

.asd__month-table {
	.arrow {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 5px 5px 0;
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
		border-top-color: #000 !important;
	}

	.asd__week {
		display: flex;

		@media (max-width:767px) {
			width: 100%;

			.asd__day {
				width: 14.28% !important;
			}
		}
	}
}

div#hc-joblisten {
	font-size: 13px;
}

div#hc-jobliste-display {
	font-size: 13px;
}

span.hc-joblist-offer-date {
	float: right;
	display: block;
}

ul.hc-joblist-offer-list {
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	border-top: 1px dotted grey;
}

li.hc-joblist-listitem {
	border-bottom: 1px dotted grey;
	padding-bottom: 5px;
	padding-top: 5px;
}

a.hc-joblist-offer-link {
	color: var(--primary);

	&:hover {
		color: #23527c;
	}
}

span.hc-joblist-offer-date {}

h2.hc-joblist-headline {
	font-size: 15px;
}

.hc-joblist-next-page {
	background: url(https://www.hotelcareer.de/images/1/arr_right2.png) no-repeat scroll center center transparent;
	height: 12px;
	width: 12px;
	display: inline-block;
}

.hc-joblist-prev-page {
	background: url(https://www.hotelcareer.de/images/1/arr_left2.png) no-repeat scroll center center transparent;
	height: 12px;
	width: 12px;
	display: inline-block;
}

.hc-joblist {
	max-width: 800px;
	margin: auto;
	font-size: 18px;
	font-weight: 300;

}

.hc-joblist-container {
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;

	.title {
		padding-bottom: 30px;
		font-size: 25px;
		text-align: center;
		line-height: 34px;
		font-weight: 500;
		text-transform: uppercase;
	}
}

.mt-2 {
	margin-top: 0.5rem;
}