@import "setup.css";

.vo-nav-bar {
	padding: 2.5rem 2.5rem 0;
	position: fixed;
	z-index: 5;
	width: 100%;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.btn-fav @media (max-width:992px) {
		padding: 2.5rem;
	}

	.logo2 {
		display: none;

		@media (max-width:992px) {
			display: none !important;
		}
	}

	.logo {
		display: inline-block;


	}




	&.affix {
		background-color: var(--bg-grey);
		padding: .5rem 2.5rem .5rem;

		.logo2 {
			display: inline-block;

			@media (max-width:992px) {
				display: block !important;
			}
		}

		.logo {
			display: none;

			@media (max-width:992px) {
				display: none !important;
			}
		}

		.main-menu li>a {
			color: var(--li-black2) !important;
		}

		.drop-nav>a {
			color: var(--li-black2) !important;



			&:hover {
				color: #1779ba !important;
			}
		}

		.btn-fav {
			color: var(--li-black2) !important;

			@media (max-width:992px) {
				top: 8px !important;
			}


			&:hover {
				color: var(--grey) !important;
			}
		}

		.btn-contact {
			color: var(--li-black2) !important;

			&:hover {
				color: var(--grey) !important;
			}

			.icon-contact {
				background-image: url(RESOURCE/img/icon-chat-black.png) !important;
			}
		}

		.btn-contact {
			@media (max-width:992px) {
				top: 8px !important;
			}

		}

		a.icon {
			color: var(--li-black2) !important;

			@media (max-width:992px) {
				top: 10px;
			}
		}
	}

	.header-left {
		@media (min-width:993px) {
			display: flex;
			align-items: center;
		}

		.logo {
			position: relative;

			@media (min-width:993px) {
				top: -2px;
			}
		}
	}

	.main-menu {
		align-items: center;
		display: flex;
		margin-left: 35px;

		@media (max-width:992px) {
			display: none;
			position: absolute;
			width: 100%;
			left: 0;
			top: 100%;
			background: var(--grey);
			max-height: 460px;
			overflow-x: scroll;


		}

		ul {
			padding: 0;
			margin-bottom: 0;
		}

		li {
			list-style: none;
			display: inline-block;
			float: left;

			@media (max-width:992px) {
				display: block;
				float: none;
			}

			>a {
				display: block;
				color: #fff;
				padding: 0 .5rem;
				line-height: 45px;
				height: 45px;
				text-decoration: none;
				font-size: 20px;

				border-bottom: 2px solid transparent;

				@media (max-width:1200px) {
					font-size: 16px;
					margin-left: .25rem;
					margin-right: .25rem;
				}

				@media (max-width:992px) {
					height: auto;
					line-height: 32px;
					font-size: 18px;
					padding: 5px 10px;
					color: var(--white);
				}

				&:hover,
				&:focus {
					border-bottom: solid 2px var(--grey);
					color: var(--grey) !important;
				}

			}
		}


	}

	.header-right {
		.btn-fav {
			color: var(--white);
			font-size: 20px;
			position: relative;
			margin-right: 25px;

			@media (max-width:992px) {
				right: 55px;
				top: 19px;
				position: absolute;
			}

			.sub-count {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 9px;
				background: var(--primary);
				border-radius: 50%;
				color: var(--white) !important;
				width: 14px;
				height: 14px;
				text-align: center;
				line-height: 13px;
			}

			&:hover {
				color: var(--black);
			}
		}

		.btn-contact {
			border: none;
			background-color: transparent;
			color: var(--white);
			position: relative;
			top: -3px;

			&:hover {
				color: var(--black);
			}

			span {
				margin-left: 10px;
				font-size: 19px;
			}

			@media (max-width:var(--navbarbreak)) {
				position: absolute;
				right: 50px;
				top: 22px;

			}

			.icon-contact {
				background-image: url(RESOURCE/img/icon-chat.png);
				width: 18px;
				height: 17px;
				display: inline-block;
				position: relative;
				top: 4px;
			}
		}

	}

	.service-nav {
		.icon-arrow-up {
			transform: translate(77px, 0) !important;

			@media (max-width:1440px) {
				transform: translate(52px, 0) !important;
			}
		}
	}


	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.drop-nav {
		position: relative;
		padding-right: .5rem;

		a {
			display: block;
			color: #fff;
			padding: 0;
			line-height: 45px;
			height: 45px;
			text-decoration: none;
			font-size: 20px;
			margin-left: .5rem;
			margin-right: .5rem;
			border-bottom: 2px solid transparent;

			@media (max-width:1200px) {
				font-size: 16px;
				margin-left: .25rem;
				margin-right: .25rem;
			}

			@media (max-width:992px) {
				padding: 5px 10px;
				line-height: 32px;
				font-size: 18px;
			}

			&:hover {
				color: #1779ba;
				border-bottom: 2px solid var(--grey);
			}
		}
	}

	.vo-dropdown .dropbtn {
		border: none;
		background: transparent;
		padding: 0;
		position: absolute;
		right: 5px;
		top: 12px;

		@media (max-width:1440px) {
			right: 0;
		}

		@media (max-width:992px) {
			right: 15px;
		}


		.fa-caret-down {
			color: var(--primary);
			margin-left: 10px;
		}


	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: var(--primary);
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
		padding: 20px;
		border-radius: 20px;

		@media (max-width:992px) {
			border-radius: 0;
			padding: 10px 20px;
		}



		.icon-arrow-up {
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid var(--primary);
			content: "";
			transform: translate(100px, 0);
			position: absolute;
			top: -10px;

			@media (max-width:1440px) {
				-webkit-transform: translate(77px, 0);
				transform: translate(77px, 0);
			}

			@media (max-width:992px) {
				display: none;
			}
		}

		/*&.mega-menu {
			max-width: 580px;
			width: 100%;
		}*/
	}

	.vo-dropdown-content a {
		float: none;
		color: var(--white);
		padding: 8px 0;
		text-decoration: none;
		display: block;
		text-align: left;
		font-size: 16px;

		@media (max-width:992px) {
			padding: 5px 0;
		}
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {}

	.vo-dropdown-content a:hover {
		color: var(--black);
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a.icon {
			float: right;
			display: block;
			color: var(--white);
			position: absolute;
			right: 20px;
			top: 25px;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}


@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar.opened {

		.main-menu {
			@media (max-width:992px) {
				display: block;
			}
		}
	}


	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
	}


}