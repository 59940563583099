@import "common.css";

/*
.vo-unit-body {
	.vo-nav-bar {
		background-color: var(--bg-grey);
		padding: .5rem 2.5rem .5rem;

		.logo2 {
			display: inline-block !important;
		}

		.logo {
			display: none;
		}

		.main-menu li>a {
			color: var(--li-black2) !important;
		}

		.dropbtn,
		.btn-fav,
		.drop-nav>a {
			color: var(--li-black2) !important;
		}

		.icon-contact {
			background-image: url(RESOURCE/img/icon-chat-black.png) !important;
		}



		.header-right,
		.header-right.affix {
			.btn-fav {
				top: -1px;

				@media (max-width:992px) {
					top: 9px !important;
				}
			}



			.btn-contact {
				top: -4px;

				@media (max-width:992px) {
					top: 12px !important;
				}
			}

		}

		a.icon {
			@media (max-width:992px) {
				color: var(--li-black2) !important;
				top: 12px !important;
			}
		}
	}


}*/

.unit-view {

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.search-section {
		.searchbox {}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {}

	}

	.ratings {
		.stars {
			color: var(--primary);
			display: inline-block;
			font-size: 26px;
		}

		blockquote {

			p,
			div {
				font-style: italic;
			}
		}

		.properties {
			margin-left: 15px;
			margin-right: 15px;
			-webkit-column-count: 2;
			-moz-column-count: 2;
			column-count: 2;
			-webkit-column-gap: 1em;
			-moz-column-gap: 1em;
			column-gap: 1em;

			.prop {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				h4 {
					display: inline-block;
					margin-right: 10px;
				}

				.stars {
					color: var(--primary);
					display: inline-block;
					font-size: 14px;
				}
			}
		}
	}

	.unitGallery {
		.imgcols {
			width: 40%;
			position: relative;
			overflow: hidden;
			margin-bottom: 20px;
			float: left;
			padding: 0 10px;

			@media (max-width:640px) {
				width: 100%;
			}

			&:first-child {
				width: 100%;

				img {
					width: 100%;
					max-height: 700px;
					height: 100%;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(8),
			&:nth-child(9),
			&:nth-child(14),
			&:nth-child(15),
			&:nth-child(20),
			&:nth-child(21),
			&:nth-child(26),
			&:nth-child(27),
			&:nth-child(32),
			&:nth-child(33),
			&:nth-child(38),
			&:nth-child(39),
			&:nth-child(44),
			&:nth-child(45) {
				width: 50%;

				@media (max-width:640px) {
					width: 100%;
				}
			}


			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(11),
			&:nth-child(12),
			&:nth-child(17),
			&:nth-child(18),
			&:nth-child(23),
			&:nth-child(24),
			&:nth-child(29),
			&:nth-child(30),
			&:nth-child(35),
			&:nth-child(36),
			&:nth-child(41),
			&:nth-child(42),
			&:nth-child(47),
			&:nth-child(48) {
				width: 60%;

				@media (max-width:640px) {
					width: 100%;
				}
			}

			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center center;
				object-position: center center;
				height: 450px;

				width: 100%;

				@media (max-width:992px) {
					height: 300px;

				}

				@media (max-width:640px) {
					max-height: 300px;
					height: auto;
				}

			}
		}
	}

	.legend-cal {

		padding: 0;
		margin: 15px 0;
		justify-content: center;
		display: flex;

		@media(max-width:992px) {
			max-width: 675px;
			margin: 0 auto;
		}

		@media(max-width:767px) {
			width: 295px;
			margin: 15px auto;
		}


		@media(min-width:993px) {
			text-align: center;
		}

		.btn-trigger {
			margin-left: 6px;
			color: var(--primary);
			font-size: 20px;
			cursor: pointer;
		}

		ul {
			list-style: none;
			text-align: left;

			@media(max-width:992px) {
				width: 100%;
			}

			@media(min-width:993px) {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: center;

			}
		}

		li {

			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			@media(max-width:992px) {
				display: flex;
			}

			.day {
				@media(max-width:992px) {
					margin-left: 0;
				}

				width: 30px;
				height: 30px;
				background-color: #7e7975;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				&.day-free {
					background: var(--white);
				}

				&.day-full {
					background: var(--cal_not_available);
				}

				&.day-end {
					background: linear-gradient(90deg, var(--cal_not_available) 20%, #fff 30%) !important;
				}

				&.day-start {
					background: linear-gradient(90deg, #fff 70%, var(--cal_not_available) 80%) !important;
				}
			}
		}
	}

	.calender-popup {
		.box {
			min-height: 350px;
			height: auto;
		}
	}

	.unit-banner {
		height: 465px;
		overflow: hidden;
		position: relative;

		.container {
			height: 465px;
			text-align: center;
			position: relative;
			z-index: 1;
			color: var(--white);
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 2;
		}

		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
			z-index: 1;
		}

		.hero {
			text-align: center;
			color: var(--white2);
			background: none;
			padding: 0;

			h1 {
				text-transform: uppercase;
			}
		}

		.home-btm {
			position: absolute;
			bottom: 30px;
			text-align: center;



			a {
				display: block;
				color: var(--white);


				.fa {
					border: 2px solid #fff;
					width: 40px;
					height: 40px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					font-size: 22px;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.title-img {

		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		max-width: 1200px;

		h2 {
			margin-top: 40px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						/* font-family: 'Indie Flower', cursive; */
					}

				}
			}


		}

		.unit-item-dt {
			max-width: 710px;
			margin: 0 auto;

			.unit-icon-flex {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				margin: 30px 0;
				flex-wrap: wrap;

				@media (max-width:767px) {
					display: block;
					text-align: center;
				}

				i {
					background-color: var(--primary);
					color: var(--white);
					width: 60px;
					height: 60px;
					border-radius: 50%;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					margin-right: 20px;
					background-position: center;
					background-size: 22px;

					@media (max-width:767px) {
						margin-right: 0;
					}
				}
			}
		}

		.description {
			padding-bottom: 30px;
		}

		.subprice {
			text-align: left;

			.price-table {


				td {
					font-size: 16px;
				}
			}
		}


		@media (min-width:768px) {
			.info-col.noaction {
				.group-fields {
					#bs-datepicker-trigger {
						border-right: 2px solid #fefefe;

						&:focus {
							border-right: 1px solid var(--grey);
						}
					}

					#guest-trigger {
						border-left: 2px solid #fefefe;

						&:focus {
							border-left: 1px solid var(--grey);
						}
					}
				}



				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: rgba(60, 63, 64, 0.9);
					color: #fff;
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}



				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}

		.info-col {
			margin-bottom: 30px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
			padding: 0 0 16px 0;

			@media (max-width: 767px) {
				padding: 0 0 30px 0;
			}

			@media (max-width: 382px) {
				padding: 0 0 50px 0;
			}


			@media all and (min-width: 768px) and (max-width: 991px) {
				padding: 0 0 40px;
			}

			>div {
				height: 100%;
				padding: 0 0 60px 0;
			}

			&.no-map {
				padding: 0 0 0 0;

				>div {
					padding: 0 0 0px 0;
				}

				.mapconsent {
					height: 100%;
					background: rgba(26, 121, 187, .15);
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					flex-direction: column;
					text-align: center;
					padding: 0 15px;

					>p {
						margin: 0 0 20px;
						font-size: 18px;
						font-weight: 600;

						@media (max-width: 767px) {
							font-size: 16px;
						}

						a {
							color: var(--primary);

							&:hover {
								color: var(--dprimary);
							}
						}
					}

					>a,
					.mapconsent-button {
						background: var(--primary);
						color: #fff;
						border-radius: 100px;
						padding: 12px 20px;
						display: block;
						font-size: 18px;

						&:hover {
							background: var(--dprimary);
						}

						@media (max-width: 767px) {
							font-size: 16px;
						}
					}

					.box {
						.body {
							text-align: left;
						}
					}
				}
			}

			.mapnote {
				font-size: 16px;
				padding: 15px 15px;

				a {
					color: var(--primary);

					&:hover {
						color: var(--dprimary);
					}
				}

				@media (max-width: 767px) {
					font-size: 14px;
					min-height: 90px;
				}

				@media (max-width: 382px) {
					font-size: 14px;
					min-height: 110px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					min-height: 100px;
				}
			}
		}
	}

	.datepicker-trigger.daterange {
		display: inline-block;
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {
			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.fa-info-col {
	i {
		margin-left: 6px;
		font-size: 20px;
		color: #aa7e37;
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}